<template>
    <div class="dibubg">
        <div class="audition con_style flex_style align_center direction_row flex_nowrap justify_around">
            <div class="from_con text_left">
                <h1>申请<i>免费</i>课程</h1>
                <p>免费领取课程，享受专职讲师给您介绍课程资料</p>
                <form action>
                    <div class="flex_style direction_row flex_nowrap justify_between align_center">
                        <p>姓名</p>
                        <input type="text" v-model="name" />
                    </div>
                    <div class="flex_style direction_row flex_nowrap justify_between align_center">
                        <p>联系电话</p>
                        <input type="text" v-model="telephone" />
                    </div>
                    <input type="button" @click="applyAction" value="立即申请" />
                </form>
            </div>
            <div class="slogan">
                <p></p>
            </div>
        </div>
    </div>
</template>
<script>
const axios = require("axios").default;
export default {
    name: 'apply_form',
    data() {
        return {
            name: '',
            telephone: ''
        }
    },
    methods: {
        applyAction() {
            var url = encodeURIComponent(window.location.href);
            var param = { name: this.name, telephone: this.telephone, source_url: url };
            axios.post("/api/audition/apply", param).then(({ data: { msg = '申请成功' } }) => {
                alert(msg);
            }).catch(({ response: { data: { errors = '申请失败' } = {} } = {} }) => {
                alert(errors);
            });
        }
    },
}
</script>
<style lang="scss" scoped>
.audition {
    height: 100%;
    // position: absolute;
    left: 21rem;
    bottom: 0;
}

.from_con h1 {
    font-size: 3.75rem;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
}

.from_con h1 i {
    color: #ff3ea6 !important;
}

.from_con>p {
    font-size: 1.5rem;
    font-family: SourceHanSansCN;
    font-weight: 200;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 20px;
}

.from_con form div {
    width: 21.458vw;
    height: 3.108vw;
    background-color: rgb(87, 25, 134);
    margin-bottom: 5px;
}

.from_con form div p {
    display: inline-block;
    width: 34%;
    height: 1.802vw;
    font-size: 1.5rem;
    font-family: SourceHanSansCN;
    font-weight: 200;
    color: rgba(171, 138, 232, 1);
    border-right: 1px solid #ab8ae8;
    text-align: justify;
    text-align-last: justify;
    box-sizing: border-box;
    padding: 0 10px;
}

.from_con form>input {
    width: 14.531vw;
    height: 3.177vw;
    background: #f91f95;
    border-radius: 11px;
    border: 0;
    outline: none;
    margin-top: 20px;
    color: #ffffff;
    font-size: 1.8rem;
}

.from_con form div input {
    width: 62%;
    height: 100%;
    color: #ffffff;
    border: 0;
    outline: none;
    background-color: #571986;
}

.slogan p {
    font-size: 4.5rem;
    font-family: SourceHanSerifCN;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
}

.text_left {
    text-align: justify !important;
}

.foot_box {
    margin-top: 0 !important;
}

.con_style {
    width: 62.5vw !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.flex_style {
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.direction_row {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    -o-flex-direction: row;
    flex-direction: row;
}

.direction_column {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
}

.flex_wrap {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
}

.flex_nowrap {
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    -o-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.align_flex-start {
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    -o-align-items: flex-start;
    align-items: flex-start;
}

.align_center {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
}

.align_end {
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    -o-align-items: flex-end;
    align-items: flex-end;
}

.justify_start {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    -o-justify-content: flex-start;
    justify-content: flex-start;
}

.justify_end {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    -o-justify-content: flex-end;
    justify-content: flex-end;
}

.justify_center {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
}

.justify_around {
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    -o-justify-content: space-around;
    justify-content: space-around;
}

.justify_between {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -o-justify-content: space-between;
    justify-content: space-between;
}

.dibubg {
    width: 100%;
    height: 26.6vw;
    background: url('../../assets/images/mflqbj.jpg') no-repeat;
    background-size: auto 100%;
    //margin-top: -1.5vw;
}</style>
